// Speedbump handling for links inside CKEditor

// ? SPEEDBUMP LINKS - EXTERNAL
// Listen to the Alpine init event
document.addEventListener('alpine:init', () => {
	// Bind the following script to all links with x-bind:speedbumpcheckexternal (added from Retcon in the text macro)
	Alpine.bind('speedbumpcheckexternal', () => ({
		'@click'() {
			// Get the href of the clicked link
			var clickedHref = this.$event.currentTarget.getAttribute('href')

			// Check to see if the clicked URL is a phone number
			// https://www.w3schools.com/jsref/jsref_search.asp
			// Returns the position, or -1 if "tel:" is not found
			var isPhone = clickedHref.search('tel:')

			// Make a URL object from it so we can get the hostname
			// https://developer.mozilla.org/en-US/docs/Web/API/URL
			var urlObject = new URL(clickedHref)

			// Get the hostname of the target link
			var targetHostname = urlObject.hostname

			// Get the hostname of the current website
			var currentHostname = window.location.hostname

			if (isPhone === -1) {
				// -1 means "tel:" wasn't found, which means it's not a phone number
				// So, check if it needs a modal
				if (targetHostname === currentHostname) {
					// The current host and target host are the same, so make it a normal link
					// Open this using JS, because you can't conditionally call @click.prevent.
					// Set _self, because otherwise it opens in a new tab
					window.open(clickedHref, '_self')
				} else {
					// The current host and target host are different
					// So, dispatch the "open a speedbump" custom event and pass it the full URL from clicked link

					this.$dispatch('modal-speedbump-external', clickedHref)
				}
			} else {
				// Anything other than -1 means the link contains "tel:" and shouldn't open a modal
				window.open(clickedHref, '_self')
			}
		},
		'@click.prevent'() {
			// Prevent default click behaviour for all links
		},
	}))
})

// ? SPEEDBUMP LINKS - PARTNER
// Listen to the Alpine init event
document.addEventListener('alpine:init', () => {
	// Bind the following script to all links with x-bind:speedbumpcheckpartner (added from Retcon in the text macro)
	Alpine.bind('speedbumpcheckpartner', () => ({
		'@click'() {
			// Get the href of the clicked link
			var clickedHref = this.$event.currentTarget.getAttribute('href')

			// Check to see if the clicked URL is a phone number
			// https://www.w3schools.com/jsref/jsref_search.asp
			// Returns the position, or -1 if "tel:" is not found
			var isPhone = clickedHref.search('tel:')

			// Make a URL object from it so we can get the hostname
			// https://developer.mozilla.org/en-US/docs/Web/API/URL
			var urlObject = new URL(clickedHref)

			// Get the hostname of the target link
			var targetHostname = urlObject.hostname

			// Get the hostname of the current website
			var currentHostname = window.location.hostname

			if (isPhone === -1) {
				// -1 means "tel:" wasn't found, which means it's not a phone number
				// So, check if it needs a modal
				if (targetHostname === currentHostname) {
					// The current host and target host are the same, so make it a normal link
					// Open this using JS, because you can't conditionally call @click.prevent.
					// Set _self, because otherwise it opens in a new tab
					window.open(clickedHref, '_self')
				} else {
					// The current host and target host are different
					// So, dispatch the "open a speedbump" custom event and pass it the full URL from clicked link

					this.$dispatch('modal-speedbump-partner', clickedHref)
				}
			} else {
				// Anything other than -1 means the link contains "tel:" and shouldn't open a modal
				window.open(clickedHref, '_self')
			}
		},
		'@click.prevent'() {
			// Prevent default click behaviour for all links
		},
	}))
})

// ? Import the CSS for Vite to process it here
import '../pcss/app.pcss'

// ? Alpine
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.start()
//

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import styles bundle
import 'swiper/css/bundle'

// Carousel / Swiper - Carousel
const swiper = new Swiper('.carousel', {
	keyboard: true, // make it navigable with a keyboard
	slidesPerView: 1,
	spaceBetween: 15,
	loop: false,
	pagination: {
		el: '.carousel-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.carousel-button-next',
		prevEl: '.carousel-button-prev',
	},
})

// Carousel / Swiper - Info Cards
const infoCardsSwiper = new Swiper('.info-cards-carousel', {
	keyboard: true, // make it navigable with a keyboard
	slidesPerView: 1.2,
	centeredSlides: true,
	spaceBetween: 0,

	pagination: {
		el: '.card-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.card-button-next',
		prevEl: '.card-button-prev',
	},

	breakpoints: {
		460: {
			slidesPerView: 1.8,
		},
		768: {
			slidesPerView: 3,
		},
	},
})
window.addEventListener('resize', infoCardsSwiper)

// Carousel - Timeline

const swiperTimeline = new Swiper('.swiper-timeline', {
	keyboard: true, // make it navigable with a keyboard
	slidesPerView: 1,
	loop: false,
	pagination: {
		el: '.timeline-pagination',
	},
	navigation: {
		nextEl: '.timeline-button-next',
		prevEl: '.timeline-button-prev',
	},
	// Responsive breakpoints
	breakpoints: {
		1024: {
			slidesPerView: 3,
		},
	},
})

/* Comparison table checkmarks ==== */
const checkmark = document.querySelectorAll(
	'.table-style-checkboxes table tr span.checkmark'
)
checkmark.forEach((item) => item.closest('td').classList.add('is-included'))

/* Jarallax */
import { jarallax } from 'jarallax'

jarallax(document.querySelectorAll('.jarallax'), {
	speed: 0.2,
})

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
